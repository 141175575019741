import {
  ListUsersResponse,
  ListUsersResult,
  User,
  UserAPI,
} from './ribbon.types'

export const transformUser = (user: UserAPI): User => ({
  id: user.id,
  firstName: user.first_name,
  lastName: user.last_name,
  permissionLevel: user.permission_level,
  email: user.email,
  lastLogin: user.last_login,
  isSso: user.is_sso,
  modules: user.modules,
  filters: user.filters,
  customFields: user.customFields,
})

export const transformListUsersResponse = (
  resp: ListUsersResponse,
): ListUsersResult => ({
  metadata: {},
  data: resp.data.map(r => transformUser(r)),
})
