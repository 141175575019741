import {
  Insurance,
  InsuranceAPI,
  InsuranceSearchResponse,
  InsuranceSearchResult,
} from './ribbon.types'

const parsePageFromNextAndPreviousLinks = (next: string, previous: string) => {
  if (next) {
    return parseInt(next.charAt(next.length - 1), 10)
  }
  if (previous) {
    return parseInt(previous.charAt(previous.length - 1), 10)
  }
  return 1
}

export const transformInsuranceAPI: (r: InsuranceAPI) => Insurance = r => ({
  uuid: r.uuid,
  carrierAssociation: r.carrier_association,
  carrierBrand: r.carrier_brand,
  carrierName: r.carrier_name,
  state: r.state,
  planName: r.plan_name,
  planType: r.plan_type,
  metalLevel: r.metal_level,
  displayName: r.display_name,
  network: r.network,
  confidence: r.confidence,
  category: r.category,
  codes: [],
})

export const transformInsuranceSearchResponse: (
  rep: InsuranceSearchResponse,
) => InsuranceSearchResult = (response: InsuranceSearchResponse) => ({
  metadata: {
    totalCount: response.count,
    page: parsePageFromNextAndPreviousLinks(response.next, response.previous),
  },
  data: response.results.map(transformInsuranceAPI),
})
