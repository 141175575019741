/* eslint-disable react/prop-types */
import { useSessionStorage } from 'hooks/useSessionStorage'
import React, { createContext, useContext } from 'react'

export const initialState = 0

export const ScrollContext = createContext(0)

export const ScrollContextProvider = ({ children }) => {
  const [scrollPos, setScrollPos] = useSessionStorage('searchScrollPos', 0)

  //   console.log('ScrollContext:State', scrollPos)

  return (
    <ScrollContext.Provider value={[scrollPos, setScrollPos]}>
      {children}
    </ScrollContext.Provider>
  )
}

export const useScrollContext = () => {
  return useContext(ScrollContext)
}
export const ScrollContextConsumer = ScrollContext.Consumer
