import {
  ProviderSearchOptions,
  ProviderSearchResult,
  ProviderSearchResponse,
} from './ribbon.types'
import { transformProviderSearchResponse } from './provider.transforms'
import queryString from 'query-string'
import { FIELD_TYPES_FLAT } from 'utils/constants'
import { get } from 'services/axios'

const resolveAgeRange = (ageRangeStr: '0-40' | '40-60' | '60+') => {
  switch (ageRangeStr) {
    case '0-40':
      return [0, 40]
    case '40-60':
      return [40, 60]
    case '60+':
      return [60, 1000]
    default:
      return [0, 1000]
  }
}

export const buildQueryFromOptions = (
  options: ProviderSearchOptions,
): { [key: string]: any } => {
  const serviceOptions: { [key: string]: any } = {}

  if (!options) {
    return serviceOptions
  }
  if (options.page) {
    serviceOptions.page = options.page
  }

  if (options.name) {
    serviceOptions.name = options.name
  }

  if (options.location) {
    serviceOptions.location = `${options.location.position.latitude},${options.location.position.longitude}`
  }

  if (options.insurance) {
    serviceOptions.location_insurance_ids = options.insurance.id
    serviceOptions.national_bluecard = true
  }

  if (options.specialties) {
    serviceOptions.specialty_ids = []

    const specialty_ids = new Set()
    options.specialties.forEach(specialty => {
      // Hardcoded uuid check on Primary Care Provider - adjusts to fuzzy search instead of search by uuid
      if (specialty.uuid === '0c949aa8-6729-41c2-9d3a-5bd4a6966363') {
        serviceOptions.specialty = 'Primary Care Provider'
      } else {
        // there shouldnt be a case for duplicates being added here
        specialty_ids.add(specialty.uuid)
        ;(specialty.include ?? []).forEach(id => specialty_ids.add(id))
      }
    })
    serviceOptions.specialty_ids = Array.from(specialty_ids)
  }

  if (options.minRating) {
    serviceOptions.min_rating = options.minRating * 2
  }
  if (options.maxDistance) {
    serviceOptions.distance = options.maxDistance
  }

  if (options.age) {
    const [min, max] = resolveAgeRange(options.age)
    serviceOptions.min_age = min
    serviceOptions.max_age = max
  }

  if (options.gender) {
    serviceOptions.gender = options.gender
  }

  if (options.languages) {
    serviceOptions.language = options.languages
  }

  if (options.outOfNetwork) {
    if (serviceOptions.location_insurance_ids) {
      delete serviceOptions.location_insurance_ids
    }
  }

  if (options.clinicalAreas) {
    serviceOptions.clinical_area_ids = options.clinicalAreas.map(ca => ca.uuid)
  }

  if (options.conditions) {
    serviceOptions.condition_ids = options.conditions.map(c => c.uuid)
  }

  if (options.treatments) {
    serviceOptions.treatment_ids = options.treatments.map(t => t.uuid)
  }

  if (options.procedures) {
    serviceOptions.procedure_ids = options.procedures.map(p => p.uuid)
  }

  if (options.procedureFuzzy) {
    serviceOptions.procedure = options.procedureFuzzy.map(p => p.display)
  }

  if (options.panelSexes) {
    serviceOptions.panel_sexes = options.panelSexes
  }

  if (options.panelAges) {
    serviceOptions.panel_ages = options.panelAges
  }

  if (options.min_efficiency_index) {
    serviceOptions.min_efficiency_index = options.min_efficiency_index
  }
  if (options.min_outcomes_index) {
    serviceOptions.min_outcomes_index = options.min_outcomes_index
  }

  if (options.preference) {
    serviceOptions.preference = options.preference.value
  }

  if (options.hasTelehealth) {
    serviceOptions.has_telehealth = options.hasTelehealth
    if (options.location) {
      serviceOptions.licensing_state = options.location.state
      delete serviceOptions.location
    }
    if (options.insurance) {
      serviceOptions.telehealth_insurance_ids = options.insurance.id
      delete serviceOptions.location_insurance_ids
    }
  }

  // Every request should have confidence unless specified by showInaccurate filter.
  if (!options.showInaccurate && !options.hasTelehealth) {
    serviceOptions.min_location_confidence = 3
  }

  // Handling custom keys separately based on is_custom field
  // passed through when filter is clicked.
  // Booleans are handled seperately to add True
  const customKeys = Object.keys(options).filter(
    key => options[key] && options[key].is_custom,
  )
  if (customKeys.length > 0) {
    customKeys.forEach(key => {
      if (options[key].values) {
        if (
          options[key].field_type &&
          options[key].field_type.toLowerCase() ===
            FIELD_TYPES_FLAT.boolean.toLowerCase()
        ) {
          serviceOptions[key] = true
        } else {
          serviceOptions[key] = options[key].values
        }
      } else {
        serviceOptions[key] = options[key].value
      }
    })
  }
  return serviceOptions
}

export const getProvidersSearchUrl = (options: ProviderSearchOptions): string => {
  // https://api.ribbonhealth.com/v1/custom/providers?provider_type=Doctor&address=60606&distance=50
  return `/custom/providers?${queryString.stringify(
    buildQueryFromOptions(options),
    {
      arrayFormat: 'comma',
    },
  )}`
}

export const searchProviders = async (
  options: ProviderSearchOptions,
): Promise<ProviderSearchResult> => {
  const url = getProvidersSearchUrl(options)

  return get<ProviderSearchResponse>(url).then(res => {
    console.log('searchProviders res', res)
    return transformProviderSearchResponse(res.data)
  })
}
