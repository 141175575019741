import {
  ProviderGetOptions,
  ProviderGetResponse,
  ProviderGetResult,
} from './ribbon.types'
import { transformProviderGetResponse } from './provider.transforms'
import { get } from 'services/axios'

export const getProvider = (
  options: ProviderGetOptions,
): Promise<ProviderGetResult> => {
  // https://api.ribbonhealth.com/v1/custom/providers/:providerId
  const url = `/custom/providers/${options.id}`

  return get<ProviderGetResponse>(url).then(res =>
    transformProviderGetResponse(res.data),
  )
}
