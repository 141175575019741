// Need to test tsconfig & app packaging when changing rootDir
// @ts-ignore
import EDUCATION_INSTITUTES from '../../static/constants/education_institutions.json'
// @ts-ignore
import LANGUAGES from '../../static/constants/languages.json'
// @ts-ignore
import LOCATION_TYPES from '../../static/constants/location_types.json'
// @ts-ignore
import EDUCATION_TYPES from '../../static/constants/education_types.json'

export const moduleTypes = {
  providers: 'providers',
  facilities: 'facilities',
  cost_estimate: 'cost_estimate',
}

export const searchTypes = {
  providers: 'providers',
  facilities: 'facilities',
}

export const GENDER_OPTIONS = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' },
]

export const DATA_SEARCH_OPTIONS = [
  { label: 'Providers', value: 'providers' },
  { label: 'Locations', value: 'locations' },
]

export const convertGenderFull = gender => {
  if (gender === 'm') return 'Male'
  if (gender === 'f') return 'Female'
  return gender
}

export const convertGenderInitial = gender => {
  if (gender === 'Male') return 'm'
  if (gender === 'Female') return 'f'
  return gender
}

export const AGE_OPTIONS = Array.from(Array(100).keys()).map(key => ({
  label: key,
  value: key,
}))

export const LANGUAGE_OPTIONS = LANGUAGES.map(item => {
  return {
    label: `${item.value[0].toUpperCase()}${item.value.slice(1)}`,
    value: `${item.value[0].toUpperCase()}${item.value.slice(1)}`,
  }
})

export const DEFAULT_LANGUAGE_OPTIONS = [
  {
    label: 'English',
    value: 'English',
  },
  {
    label: 'Spanish',
    value: 'Spanish',
  },
]

export const FACILITY_OPTIONS = LOCATION_TYPES.map(item => {
  return {
    label: item.value,
    value: item.value,
  }
})

export const EDUCATION_OPTIONS = EDUCATION_INSTITUTES.map(item => {
  return {
    label: item.name,
    value: item.name,
    data: item,
  }
})

export const EDUCATION_TYPE_OPTIONS = EDUCATION_TYPES.map(item => {
  return {
    label: item,
    value: item,
  }
})

// Filtering with promises because list is very long to display entirely.

export const filterEducations = input => {
  return new Promise(resolve => {
    if (!input && typeof input !== 'string') {
      resolve([])
    }
    resolve(
      EDUCATION_OPTIONS.filter(option => {
        return option.value.toLowerCase().includes(input.toLowerCase())
      }),
    )
  })
}

export const filterLanguages = input => {
  return new Promise(resolve => {
    if (!input && typeof input !== 'string') {
      resolve([])
    }
    resolve(
      LANGUAGE_OPTIONS.filter(option => {
        return option.value.toLowerCase().includes(input.toLowerCase())
      }),
    )
  })
}

const getRangeOfYears = (start, end) => {
  var list = []
  for (var i = end; i >= start; i--) {
    list.push({
      label: i,
      value: i,
    })
  }
  return list
}

export const YEAR_OPTIONS = getRangeOfYears(
  new Date().getFullYear() - 100,
  new Date().getFullYear(),
)

export const NEW_LOCATION = {
  address: '',
  addressDetails: {
    zip: '',
    city: '',
    state: '',
    street: '',
    addressLine1: '',
    addressLine2: '',
  },
  name: '',
  phoneNumbers: [],
  faxes: [],
  locationTypes: [],
}

export const EDIT_TYPES = {
  insurances: 'insurances',
  addInsurances: 'addInsurances',
  location: 'location',
  addLocation: 'addLocation',
  info: 'info',
  notes: 'notes',
  addNotes: 'addNotes',
}

export const FIELD_TYPES_FLAT = {
  boolean: 'Boolean',
  string: 'String',
  float: 'Float',
  integer: 'Integer',
  date: 'Date',
  list: 'List',
  object: 'Object',
}

export const FIELD_TYPES_FLAT_LOWER = {
  boolean: 'boolean',
  string: 'string',
  float: 'float',
  integer: 'integer',
  date: 'date',
  list: 'list',
  object: 'object',
}

export const FIELD_TYPES = [
  {
    label: 'Boolean',
    value: 'boolean',
  },
  {
    label: 'String',
    value: 'string',
  },
  {
    label: 'Float',
    value: 'float',
  },
  {
    label: 'Integer',
    value: 'integer',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'List',
    value: 'list',
  },
  {
    label: 'Object',
    value: 'object',
  },
]

export const OBJECT_FIELD_TYPES = [
  {
    label: 'Boolean',
    value: 'boolean',
  },
  {
    label: 'String',
    value: 'string',
  },
  {
    label: 'Float',
    value: 'float',
  },
  {
    label: 'Integer',
    value: 'integer',
  },
  {
    label: 'Date',
    value: 'date',
  },
]

export const LIST_FIELD_TYPES = [
  {
    label: 'Boolean',
    value: 'boolean',
  },
  {
    label: 'String',
    value: 'string',
  },
  {
    label: 'Float',
    value: 'float',
  },
  {
    label: 'Integer',
    value: 'integer',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Object',
    value: 'object',
  },
]
