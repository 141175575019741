import axios from 'axios'

export type ChangePasswordOptions = {
  old_password: string
  new_password1: string
  new_password2: string
}

export type ChangePasswordResult = {
  success: boolean
}

type ConfigureChangePasswordServiceType = (
  CHANGE_PASSWORD_ENDPOINT_URL: string,
) => (
  token: string,
  options: ChangePasswordOptions,
) => Promise<ChangePasswordResult>

export const configureChangePasswordService: ConfigureChangePasswordServiceType = CHANGE_PASSWORD_ENDPOINT_URL => (
  token,
  options,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  return axios
    .post<ChangePasswordResult>(CHANGE_PASSWORD_ENDPOINT_URL, options, {
      headers,
    })
    .then(res => res.data)
}
