import axios, { AxiosResponse } from 'axios'
import { ApiPostOptions, ApiPutOptions } from './ribbon/ribbon.types'

const axiosClient = axios.create()

axiosClient.defaults.baseURL = process.env.GATSBY_RIBBON_ENDPOINT_BASE

axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

//All request will wait 30 seconds before timeout
axiosClient.defaults.timeout = 30000

const getAuthToken = () => {
  const parsedAuthLocalStorage =
    typeof localStorage !== 'undefined'
      ? JSON.parse(localStorage.getItem('auth') || '')
      : {}
  return parsedAuthLocalStorage.token
}

export const get = async <T>(url: string): Promise<AxiosResponse<T>> => {
  const token = getAuthToken()
  // console.log('token :>> ', token);

  return axiosClient.get<T>(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
}

export const post = async <T>(
  url: string,
  data: ApiPostOptions,
  headers?: Record<string, string>,
): Promise<AxiosResponse<T>> => {
  const token = getAuthToken()
  // console.log('token :>> ', token);

  return axiosClient.post<T>(url, data, {
    headers: {
      authorization: `Bearer ${token}`,
      ...(headers || {}),
    },
  })
}

export const put = async <T>(
  url: string,
  data: ApiPutOptions,
  headers?: Record<string, string>,
): Promise<AxiosResponse<T>> => {
  const token = getAuthToken()
  // console.log('token :>> ', token);

  return axiosClient.put<T>(url, data, {
    headers: {
      authorization: `Bearer ${token}`,
      ...(headers || {}),
    },
  })
}

export default axiosClient
