import * as styledComponents from 'styled-components'

import { borderRadius } from './border-radius'

import { animations } from './animations'
import { flexboxgrid, breakpointMediaQueries } from './flexboxgrid'
import { colors } from './colors'
import { space, spacing, sizes, headingSizes, bodySizes } from './sizing'
import { transitionEasing, transitions, transitionSpeed } from './transitions'
import { fontSize, fontSizes, letterSpacing } from './typography'
import { fontWeights } from './weight'
import { zIndexes } from './z-index'

const {
  default: styled,
  css,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
  ThemeInterface
>

export interface ThemeInterface {
  animations: { [key: string]: styledComponents.Keyframes }
  borderRadius: string[]
  breakpointMediaQueries: { [key: string]: string }
  flexboxgrid: {
    gridSize: number
    gutterWidth: number
    outerMargin: number
    mediaQuery: string
    container: {
      sm: number
      md: number
      lg: number
    }
    breakpoints: {
      xs: number
      sm: number
      md: number
      lg: number
    }
  }
  colors: { [key: string]: string }
  fontSize: { [key: string]: string }
  fontSizes: number[]
  fontWeights: { [key: string]: number }
  letterSpacing: { [key: string]: string }
  spacing: { [key: string]: string }
  space: string[]
  sizes: { [key: string]: string }
  headingSizes: { [key: string]: string }
  bodySizes: { [key: string]: string }
  textStyles: any
  transitions: { [key: string]: string | string }
  transitionEasing: { [key: string]: string }
  transitionSpeed: { [key: string]: string }
  zIndexes: { [key: string]: number }
}

export const theme: ThemeInterface = {
  animations,
  borderRadius,
  colors,
  fontSize,
  fontSizes,
  fontWeights,
  breakpointMediaQueries,
  flexboxgrid,
  letterSpacing,
  space,
  spacing,
  sizes,
  headingSizes,
  bodySizes,
  textStyles: {
    caps: {
      textTransform: 'uppercase',
    },
    italic: {
      fontStyle: 'italic',
    },
  },
  transitionEasing,
  transitionSpeed,
  transitions,
  zIndexes,
}

export default styled
export { css, keyframes, ThemeProvider }
