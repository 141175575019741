export type GeocodeOptionsType = {
  searchString: string
  sessionToken: string
  types: string[]
}

export type GeocodeReturnType = {
  data: google.maps.places.AutocompletePrediction[]
}

type FindPlaceFromPlaceIdResultType = google.maps.places.PlaceResult | unknown

export const configureGeocodeService = (
  options: GeocodeOptionsType,
): Promise<GeocodeReturnType['data']> => {
  const { searchString, sessionToken, types = [] } = options
  return new Promise<GeocodeReturnType['data']>(resolve => {
    const placesService = new window.google.maps.places.AutocompleteService()

    const displaySuggestions = (
      predictions: google.maps.places.AutocompletePrediction[],
      status: google.maps.places.PlacesServiceStatus,
    ) => {
      console.log(predictions, status)
      if (status === 'OK') {
        resolve(predictions)
      } else {
        resolve([])
      }
    }

    if (placesService) {
      placesService.getPlacePredictions(
        {
          input: searchString,
          sessionToken,
          types,
          componentRestrictions: { country: 'us' },
        },
        displaySuggestions,
      )
    }
  })
}

export const findPlaceFromPlaceId = (
  placeId: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  sessionToken: any,
): Promise<FindPlaceFromPlaceIdResultType> => {
  console.log('placeId', placeId)
  // map is required for PlacesService to work..
  const map = new window.google.maps.Map(document.createElement('div'))
  return new Promise((resolve, reject) => {
    const placeDetailsService = new window.google.maps.places.PlacesService(map)
    placeDetailsService.getDetails(
      {
        fields: ['address_components', 'geometry'],
        placeId: placeId,
        sessionToken: sessionToken.current,
      },
      (placeResult, status) => {
        console.log('placeResult', placeResult, status)
        if (status === 'OK') {
          sessionToken = null
          resolve(placeResult)
        } else if (
          status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
        ) {
          return resolve({})
        } else {
          return reject(status)
        }

        resolve({})
      },
    )
  })
}

export const reverseGeocode = ({
  lat,
  lng,
}: {
  lat: number
  lng: number
}): Promise<FindPlaceFromPlaceIdResultType> => {
  const geocoder = new window.google.maps.Geocoder()
  return new Promise((resolve, reject) => {
    if (!lat || !lng) resolve([])
    geocoder.geocode(
      {
        location: {
          lat,
          lng,
        },
      },
      (results, status) => {
        console.log('geocoder Results: ', results, status)
        if (status === 'OK') {
          resolve(results)
        } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          return resolve([])
        } else {
          return reject(status)
        }

        return resolve([])
      },
    )
  })
}
