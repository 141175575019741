import { searchLocations } from './search-locations.service'
import { searchProviders } from './search-providers.service'
import { searchInsurances } from './search-insurances.service'
import { configureSearchSpecialtiesService } from './search-specialties.service'
import { getProvider } from './get-provider.service'
import { getLocation } from './get-location.service'
import { getLocationTypes } from './list-facility-types'
import { configureAuthenticateService } from './authenticate.service'
import { configureChangePasswordService } from './change-password.service'
import { configureResetPasswordService } from './reset-password.service'
import { configureListUsersService } from './list-users.service'
import { configureCreateUserService } from './create-user.service'
import { configureUpdateUserService } from './update-user.service'
import { configureDeleteUserService } from './delete-user.service'
import { RibbonServicesType } from './ribbon.types'
import { configureCheckAuthService } from './check-auth.service'
import { configureConfirmResetPasswordService } from './confirm-reset-password.service'
import { configureAddLocationService } from './add-location.service'
import { updateLocationInsurances } from './update-location-insurances.service'
import { updateProviderLocationInsurances } from './update-provider-location-insurances.service'
import { configureUpdateProviderInfoService } from './update-provider-info.service'
import { configureUpdateProviderSpecialtyService } from './update-provider-specialties.service'
import { configureUpdateProviderLocationService } from './add-provider-location.service'
import { updateProviderLocationData } from './update-provider-location-data.service'
import { updateLocationData } from './update-location-data.service'
import {
  configureSearchClinicalAreasService,
  configureSearchConditionsService,
  configureSearchTreatmentsService,
} from './search-clinical-areas.service'
import { configureSearchProceduresService } from './search-procedures.service'
import { configureListApplicationsService } from './list-applications.service'
import { configureManageInstanceService } from './manage-instance.service'
import { configureVerifyTokenService } from './verify-token.service'
import { configureCreateAccountService } from './create-account.service'
import { configureResendInviteService } from './resend-invite.service'
import { configureDevelopersSearchDataService } from './developers-search.service'
import { configureCreateFilterService } from './create-filter.service'
import { configureCreateCustomFieldService } from './create-custom-field.service'
import { configureSearchProceduresCostEstimatesService } from './search-procedure-cost-estimates.service'
import { configureGetProcedureService } from './get-procedure.service'
import { createNote } from './create-note.service'
import { manageConfidence } from './manage-confidence.service'
import { configureLoginSso } from 'services/ribbon/sso-login.service'
import { getProviderFacetCounts } from 'services/ribbon/get-provider-facet-count.service'

const ENDPOINT_BASE = process.env.GATSBY_RIBBON_ENDPOINT_BASE

export const configureRibbonServices = (): RibbonServicesType => {
  return {
    authenticate: configureAuthenticateService(`${ENDPOINT_BASE}/authenticate`),
    verifyToken: configureVerifyTokenService(`${ENDPOINT_BASE}/verify-token`),
    createAccount: configureCreateAccountService(
      `${ENDPOINT_BASE}/create-account`,
    ),
    checkAuth: configureCheckAuthService(`${ENDPOINT_BASE}/check-auth`),
    resendInvite: configureResendInviteService(
      `${ENDPOINT_BASE}/resend-invite`,
    ),
    changePassword: configureChangePasswordService(
      `${ENDPOINT_BASE}/password-change`,
    ),
    confirmResetPassword: configureConfirmResetPasswordService(
      `${ENDPOINT_BASE}/password-reset/confirm`,
    ),
    resetPassword: configureResetPasswordService(
      `${ENDPOINT_BASE}/password-reset`,
    ),
    searchProviders,
    developerSearchData: configureDevelopersSearchDataService(
      `${ENDPOINT_BASE}/custom`,
    ),
    getProvider,
    getProviderFacetCounts,
    getLocation,
    getLocationTypes,
    createNote,
    searchLocations,
    searchInsurances,
    searchSpecialties: configureSearchSpecialtiesService(
      `${ENDPOINT_BASE}/custom/specialties`,
    ),
    searchClinicalAreas: configureSearchClinicalAreasService(
      `${ENDPOINT_BASE}/custom/clinical_areas`,
    ),
    searchConditions: configureSearchConditionsService(
      `${ENDPOINT_BASE}/custom/conditions`,
    ),
    searchTreatments: configureSearchTreatmentsService(
      `${ENDPOINT_BASE}/custom/treatments`,
    ),
    searchProcedures: configureSearchProceduresService(
      `${ENDPOINT_BASE}/custom/procedures`,
    ),
    searchProceduresCostEstimates: configureSearchProceduresCostEstimatesService(
      `${ENDPOINT_BASE}/procedure_cost_estimate`,
    ),
    getProcedure: configureGetProcedureService(`${ENDPOINT_BASE}/procedures`),
    // Edit / Add Services
    addLocation: configureAddLocationService(
      `${ENDPOINT_BASE}/custom/locations`,
    ),
    updateLocationInsurances,
    updateLocationData,
    updateProviderLocations: configureUpdateProviderLocationService(
      `${ENDPOINT_BASE}/custom/providers`,
    ),
    updateProviderLocationInsurances,
    updateProviderLocationData,
    updateProviderInfo: configureUpdateProviderInfoService(
      `${ENDPOINT_BASE}/custom/providers`,
    ),
    updateProviderSpecialties: configureUpdateProviderSpecialtyService(
      `${ENDPOINT_BASE}/custom/providers`,
    ),
    manageConfidence,
    listUsers: configureListUsersService(`${ENDPOINT_BASE}/users`),
    createUser: configureCreateUserService(`${ENDPOINT_BASE}/users`),
    updateUser: configureUpdateUserService(`${ENDPOINT_BASE}/users`),
    deleteUser: configureDeleteUserService(`${ENDPOINT_BASE}/users`),
    // Admin
    listApplications: configureListApplicationsService(
      `${ENDPOINT_BASE}/applications`,
    ),
    manageInstance: configureManageInstanceService(
      `${ENDPOINT_BASE}/applications`,
    ),
    createProvidersFilter: configureCreateFilterService(
      `${ENDPOINT_BASE}/custom/providers/filters`,
    ),
    createLocationsFilter: configureCreateFilterService(
      `${ENDPOINT_BASE}/custom/locations/filters`,
    ),
    createCustomField: configureCreateCustomFieldService(
      `${ENDPOINT_BASE}/manage-fields`,
    ),
    loginSso: configureLoginSso(`${ENDPOINT_BASE}/sso-login`),
  }
}
