import axios from 'axios'
import {
  SearchProceduresOptions,
  SearchProceduresResult,
  SearchProceduresResponse,
} from './ribbon.types'
import queryString from 'query-string'
import { transformSearchProceduresResponse } from './procedures.transforms'

export const configureSearchProceduresService = (ENDPOINT_URL: string) => (
  token: string,
  options: SearchProceduresOptions,
): Promise<SearchProceduresResult> => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  // https://api.ribbonhealth.com/v1/custom/procedures?search=
  const url = `${ENDPOINT_URL}?${queryString.stringify(options)}`

  return axios
    .get<SearchProceduresResponse>(url, { headers })
    .then(res => {
      console.log('res :>> ', res)
      return transformSearchProceduresResponse(res.data)
    })
}
