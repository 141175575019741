import axios from 'axios'
import {
  ConfirmResetPasswordOptions,
  ConfirmResetPasswordResult,
} from './ribbon.types'

type ConfigureConfirmResetPasswordServiceType = (
  CONFIRM_RESET_PASSWORD_ENDPOINT_URL: string,
) => (
    options: ConfirmResetPasswordOptions,
  ) => Promise<ConfirmResetPasswordResult>

export const configureConfirmResetPasswordService: ConfigureConfirmResetPasswordServiceType = CONFIRM_RESET_PASSWORD_ENDPOINT_URL => (
  options: ConfirmResetPasswordOptions,
) => {
  return axios
    .post<ConfirmResetPasswordResult>(
      `${CONFIRM_RESET_PASSWORD_ENDPOINT_URL}/${options.uid}/${options.token}`,
      { password: options.password },
    )
    .then(res => res.data)
}
