import axios from 'axios'
import {
  ListApplicationsOptions,
  ListApplicationsResponse,
} from './ribbon.types'
import queryString from 'query-string'

export const configureListApplicationsService = (ENDPOINT_URL: string) => (
  token: string,
  options: ListApplicationsOptions,
): Promise<ListApplicationsResponse> => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  const url = `${ENDPOINT_URL}?${queryString.stringify(options)}`

  return axios
    .get<ListApplicationsResponse>(url, { headers })
    .then(res => {
      console.log('res :>> ', res)
      const { data } = res.data

      return {
        parameters: res.data.parameters,
        data: data,
      }
    })
}
