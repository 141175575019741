import { transformInsuranceAPI } from './insurance.transforms'
import {
  Location,
  LocationAPI,
  LocationGetResponse,
  LocationSearchResponse,
  LocationSearchResult,
} from './ribbon.types'

export const transformLocationGetResponse: (
  p: LocationGetResponse,
) => Location = location => transformLocation(location)

export const transformLocationAddResponse: (
  p: LocationAPI,
) => Location = location => transformLocation(location)

export const transformLocation: (l: LocationAPI) => Location = ({
  name,
  uuid,
  faxes,
  phone_numbers,
  address,
  address_details: { zip, city, state, street, address_line_1, address_line_2 },
  google_maps_link,
  distance,
  confidence,
  latitude,
  longitude,
  location_types,
  insurances,
  verified,
  verified_at,
  insurance_verifications,
  ...rest
}): Location => ({
  // include all fields - mainly to catch custom fields created
  ...rest,
  // known fields for location transformed
  latitude,
  longitude,
  address,
  locationTypes: location_types,
  addressDetails: {
    zip,
    city,
    state,
    street,
    addressLine1: address_line_1,
    addressLine2: address_line_2,
  },
  insurances: (insurances || []).map(transformInsuranceAPI),
  insuranceVerifications: insurance_verifications,
  confidence,
  distance,
  googleMapsLink: google_maps_link,
  name,
  faxes: faxes || [],
  phoneNumbers: phone_numbers || [],
  uuid,
  verified,
  verified_at,
})

export const transformLocationSearchResponse: (
  rep: LocationSearchResponse,
) => LocationSearchResult = (response: LocationSearchResponse) => ({
  metadata: {
    address: response.parameters.address,
    distance: response.parameters.distance,
    geo: response.parameters.geo,
    page: response.parameters.page,
    pageSize: response.parameters.page_size,
    insuranceIds: response.parameters.insurance_ids,
    sortBy: response.parameters.sort_by,
    totalCount: response.parameters.total_count,
    locationTypes: response.parameters.location_types,
  },
  data: response.data.map(r => {
    // console.log('location res :>> ', r)
    return transformLocation(r)
  }),
})
