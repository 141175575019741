import { post } from 'services/axios'
import {
  ManageConfidenceOptions,
  ManageConfidenceResponse,
} from './ribbon.types'

export const manageConfidence = async (
  action: string,
  options: ManageConfidenceOptions,
): Promise<ManageConfidenceResponse> => {
  const res = await post<ManageConfidenceResponse>(
    `/manage_confidence_scores?request_type=${action}`,
    options,
  )
  return res.data
}
