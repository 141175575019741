import {
  ClinicalAreasSearchResponse,
  ClinicalAreasSearchResult,
  ClinicalAreaTransformedSearchResult,
  ConditionsSearchResponse,
  TreatmentsSearchResponse,
} from './ribbon.types'

const parsePageFromNextAndPreviousLinks = (
  next: string | null,
  previous: string | null,
) => {
  if (next) {
    return parseInt(next.charAt(next.length - 1), 10)
  }
  if (previous) {
    return parseInt(previous.charAt(previous.length - 1), 10)
  }
  return 1
}

export const transformClinicalAreasSearchResponse = (
  response: ClinicalAreasSearchResponse,
): ClinicalAreasSearchResult => {
  const { results = [] } = response
  const flattedResults: ClinicalAreaTransformedSearchResult[] = results.reduce(
    (result: ClinicalAreaTransformedSearchResult[], curr) => {
      result.push({
        uuid: curr.uuid,
        display: curr.display,
        type: 'clinicalAreas',
        conditions: curr.conditions,
        treatments: curr.treatments,
      })
      // const { conditions, treatments } = curr
      // conditions.forEach(condition =>
      //   result.push({
      //     uuid: condition.uuid,
      //     display: condition.display,
      //     type: 'conditions',
      //   }),
      // )
      // treatments.forEach(treatment =>
      //   result.push({
      //     uuid: treatment.uuid,
      //     display: treatment.display,
      //     type: 'treatments',
      //   }),
      // )

      return result
    },
    [],
  )

  return {
    metadata: {
      totalCount: response.count,
      page: parsePageFromNextAndPreviousLinks(response.next, response.previous),
    },
    data: flattedResults,
  }
}

export const transformConditionsSearchResponse = (
  response: ConditionsSearchResponse,
): ClinicalAreasSearchResult => {
  const { results = [] } = response
  const flattedResults: ClinicalAreaTransformedSearchResult[] = results.reduce(
    (result: ClinicalAreaTransformedSearchResult[], curr) => {
      result.push({
        uuid: curr.uuid,
        display: curr.display,
        type: 'conditions',
      })
      return result
    },
    [],
  )

  return {
    metadata: {
      totalCount: response.count,
      page: parsePageFromNextAndPreviousLinks(response.next, response.previous),
    },
    data: flattedResults,
  }
}

export const transformTreatmentsSearchResponse = (
  response: TreatmentsSearchResponse,
): ClinicalAreasSearchResult => {
  const { results = [] } = response
  const flattedResults: ClinicalAreaTransformedSearchResult[] = results.reduce(
    (result: ClinicalAreaTransformedSearchResult[], curr) => {
      result.push({
        uuid: curr.uuid,
        display: curr.display,
        type: 'treatments',
      })
      return result
    },
    [],
  )

  return {
    metadata: {
      totalCount: response.count,
      page: parsePageFromNextAndPreviousLinks(response.next, response.previous),
    },
    data: flattedResults,
  }
}
