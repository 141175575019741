import axios from 'axios'
import { APISuccessResponse, CreateUserOptions } from './ribbon.types'

type ConfigureCreateUserServiceType = (
  CREATE_USER_ENDPOINT_URL: string,
) => (token: string, user: CreateUserOptions) => Promise<APISuccessResponse>

export const configureCreateUserService: ConfigureCreateUserServiceType = CREATE_USER_ENDPOINT_URL => (
  token,
  options: CreateUserOptions,
) => {
  console.log('🚀 => create user options', options)
  const headers = {
    authorization: `Bearer ${token}`,
  }
  return axios
    .post<APISuccessResponse>(CREATE_USER_ENDPOINT_URL, options, { headers })
    .then(res => res.data)
}
