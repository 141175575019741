import axios from 'axios'
import {
  UpdateProviderLocationsOptions,
  UpdateProviderLocationsResponse,
} from './ribbon.types'

type ConfigureUpdateProviderLocationServiceType = (
  ENDPOINT_URL: string,
) => (
  token: string,
  providerId: string,
  options: UpdateProviderLocationsOptions,
) => Promise<UpdateProviderLocationsResponse>

export const configureUpdateProviderLocationService: ConfigureUpdateProviderLocationServiceType = ENDPOINT_URL => (
  token,
  providerId,
  options,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
    'content-type': 'application/json',
  }

  // https://api.ribbonhealth.com/v1/custom/providers/npi/locations
  const url = `${ENDPOINT_URL}/${providerId}/locations`

  return axios
    .put<UpdateProviderLocationsResponse>(url, options, { headers })
    .then(res => {
      console.log('UpdateProviderLocationsResponse', res.data)
      return res.data
    })
}
