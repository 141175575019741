import axios from 'axios'
import {
  UpdateProviderInfoOptions,
  UpdateProviderInfoResponse,
} from './ribbon.types'

type ConfigureUpdateProviderInfoServiceType = (
  ENDPOINT_URL: string,
) => (
  token: string,
  providerId: string,
  options: UpdateProviderInfoOptions,
) => Promise<UpdateProviderInfoResponse>

export const configureUpdateProviderInfoService: ConfigureUpdateProviderInfoServiceType = ENDPOINT_URL => (
  token,
  providerId,
  options,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
    'content-type': 'application/json',
  }

  // https://api.ribbonhealth.com/v1/custom/providers/<providerId>
  const url = `${ENDPOINT_URL}/${providerId}`
  return axios
    .put<UpdateProviderInfoResponse>(url, options, { headers })
    .then(res => {
      console.log('UpdateProviderInfoResponse', res.data)
      return res.data
    })
}
