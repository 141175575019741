import React from 'react'

import { PropTypes } from 'prop-types'

const defaultValue = { xl: true, lg: false, md: false, sm: false, xs: false }

const BreakpointContext = React.createContext(defaultValue)

const BreakpointProvider = ({ children, queries }) => {
  const [queryMatch, setQueryMatch] = React.useState({})

  React.useEffect(() => {
    const keys = ['xl', 'lg', 'md', 'sm', 'xs']
    let isAttached = false

    const mediaQueryLists =
      window && window.matchMedia
        ? keys.reduce(
            (acc, size) => ({
              ...acc,
              [size]: window.matchMedia(queries[size]),
            }),
            {},
          )
        : {}

    const checkMediaQueries = () => {
      if (window && window.matchMedia) {
        let alreadyMatched = false
        const updatedMatches = keys.reduce((acc, media) => {
          acc[media] =
            !alreadyMatched &&
            !!(mediaQueryLists[media] && mediaQueryLists[media].matches)
          alreadyMatched = alreadyMatched || acc[media]
          return acc
        }, {})
        setQueryMatch(updatedMatches)
      }
    }

    checkMediaQueries()
    isAttached = true

    keys.forEach(size => {
      if (typeof queries[size] === 'string') {
        if (mediaQueryLists[size]) {
          mediaQueryLists[size].addListener(checkMediaQueries)
        }
      }
    })

    return () => {
      if (isAttached) {
        keys.forEach(size => {
          if (typeof queries[size] === 'string') {
            if (mediaQueryLists[size]) {
              mediaQueryLists[size].removeListener(checkMediaQueries)
            }
          }
        })
      }
    }
  }, [queries])

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  )
}

BreakpointProvider.propTypes = {
  children: PropTypes.object,
  queries: PropTypes.object,
}

function useBreakpoint() {
  return React.useContext(BreakpointContext)
}

export { useBreakpoint, BreakpointProvider }
