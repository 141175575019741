import axios from 'axios'
import { VerifyTokenOptions, VerifyTokenResponse } from './ribbon.types'

export const configureVerifyTokenService = (ENDPOINT_URL: string) => (
  options: VerifyTokenOptions,
): Promise<VerifyTokenResponse> => {
  const headers = {}

  const url = `${ENDPOINT_URL}/${options.uid}/${options.token}`

  return axios
    .get<VerifyTokenResponse>(url, { headers })
    .then(res => {
      return res.data
    })
}
