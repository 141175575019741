import axios from 'axios'
import { transformAuthResponse } from './authenticate.transforms'
import {
  AuthenticateOptions,
  AuthenticateResponse,
  AuthenticateResult,
} from './ribbon.types'

type ConfigureAuthenticateServiceType = (
  AUTHENTICATE_ENDPOINT_URL: string,
) => (options: AuthenticateOptions) => Promise<AuthenticateResult>

export const configureAuthenticateService: ConfigureAuthenticateServiceType = AUTHENTICATE_ENDPOINT_URL => options => {
  const headers = {
    'content-type': 'application/json',
  }

  return axios
    .post<AuthenticateResponse>(AUTHENTICATE_ENDPOINT_URL, options, {
      headers,
    })
    .then(res => transformAuthResponse(res.data))
}
