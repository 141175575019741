import { put } from 'services/axios'
import {
  UpdateLocationInsurancesOptions,
  UpdateProviderLocationInsurancesResponse,
} from './ribbon.types'

export const updateProviderLocationInsurances = async (
  providerId: string,
  locationId: string,
  options: UpdateLocationInsurancesOptions,
): Promise<UpdateProviderLocationInsurancesResponse> => {
  // https://api.ribbonhealth.com/v1/custom/providers/<providerId>/locations/<locationid>/insurances
  const res = await put<UpdateProviderLocationInsurancesResponse>(
    `/custom/providers/${providerId}/locations/${locationId}/insurances`,
    options,
  )
  return res.data
}
