import { transformInsuranceAPI } from './insurance.transforms'
import { transformLocation } from './location.transforms'
import {
  Provider,
  ProviderAPI,
  ProviderGetResponse,
  ProviderSearchResponse,
  ProviderSearchResult,
} from './ribbon.types'
import { transformSpecialtyAPI } from './specialty.transforms'
import { transformPartnerDataAPI } from 'services/ribbon/partner-data.transforms'

export const transformProviderGetResponse: (
  p: ProviderGetResponse,
) => Provider = providerAPI => transformProvider(providerAPI)

const transformProvider = (r: ProviderAPI): Provider => ({
  // include all fields - mainly to catch custom fields created
  ...r,
  // Add and transform known variable names how App expects
  npi: r.npi,
  firstName: r.first_name,
  middleName: r.middle_name,
  lastName: r.last_name,
  gender: r.gender,
  distance: r.distance,
  age: r.age,
  educations: r.educations,
  ratingsAvg: r.ratings_avg,
  ratingsCount: r.ratings_count,
  locations: r.locations.map(transformLocation),
  insurances: r.insurances.map(transformInsuranceAPI),
  languages: r.languages,
  specialties: r.specialties.map(transformSpecialtyAPI),
  clinicalAreas: r.clinical_areas,
  panelDemographics: r.panel_demographics,
  procedures: r.procedures,
  performance: r.performance,
  partnerData: r.partner_data?.map(transformPartnerDataAPI),
  preference: r.preference,
})

export const transformProviderSearchResponse: (rep: ProviderSearchResponse) => ProviderSearchResult = (
  providerSearchResponse: ProviderSearchResponse,
) => ({
  metadata: {
    address: providerSearchResponse.parameters.address,
    distance: providerSearchResponse.parameters.distance,
    geo: providerSearchResponse.parameters.geo,
    page: providerSearchResponse.parameters.page,
    pageSize: providerSearchResponse.parameters.page_size,
    insuranceIds: providerSearchResponse.parameters.insurance_ids,
    specialtyIds: providerSearchResponse.parameters.specialty_ids,
    specialty: providerSearchResponse.parameters.specialty,
    sortBy: providerSearchResponse.parameters.sort_by,
    totalCount: providerSearchResponse.parameters.total_count,
  },
  data: providerSearchResponse.data.map(r => transformProvider(r)),
})
