import {
  LocationGetOptions,
  LocationGetResult,
  LocationGetResponse,
} from './ribbon.types'
import { transformLocationGetResponse } from './location.transforms'
import { get } from 'services/axios'

export const getLocation = (
  options: LocationGetOptions,
): Promise<LocationGetResult> => {
  // https://api.ribbonhealth.com/v1/custom/locations/:locationId
  const url = `/custom/locations/${options.id}`

  return get<LocationGetResponse>(url).then(res =>
    transformLocationGetResponse(res.data),
  )
}
