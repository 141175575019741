import axios from 'axios'
import { transformLocationAddResponse } from './location.transforms'
import {
  LocationAddOptions,
  LocationAddResult,
  LocationAddResponse,
} from './ribbon.types'

type ConfigureAddLocationServiceType = (
  LOCATIONS_ENDPOINT_URL: string,
) => (token: string, options: LocationAddOptions) => Promise<LocationAddResult>

export const configureAddLocationService: ConfigureAddLocationServiceType = LOCATIONS_ENDPOINT_URL => (
  token,
  options,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
    'content-type': 'application/json',
  }

  // https://api.ribbonhealth.com/v1/custom/locations
  const url = `${LOCATIONS_ENDPOINT_URL}`

  return axios
    .post<LocationAddResponse>(url, options, { headers })
    .then(res => {
      const { data } = res.data
      return transformLocationAddResponse(data)
    })
}
