/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

require('./src/theme/css/fonts.css')
require('react-datepicker/dist/react-datepicker.css')
require('notyf/notyf.min.css')
require('./src/theme/css/theme.css')
const React = require('react')
const PropTypes = require('prop-types')
const {
  GlobalContextProvider,
} = require('./src/context/global-context-provider')
const { theme, ThemeProvider } = require('theme')
const { BreakpointProvider } = require('context/breakpoint-context')
const { AuthStateProvider } = require('context/auth-state-context')
const { QueryClient, QueryClientProvider } = require('react-query')
const { CostEstimatesProvider } = require('context/cost-estimates-context')
const { ScrollContextProvider } = require('context/scroll-context')

if (process.env.NODE_ENV !== 'production') {
  // Gtag is only added in production builds, but tracking code
  // is placed around some button clicks, so setting this here to work in dev
  if (window) window.gtag = () => {}
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const Wrapper = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthStateProvider>
        <GlobalContextProvider>
          <CostEstimatesProvider>
            <ScrollContextProvider>
              <ThemeProvider theme={theme}>
                <BreakpointProvider queries={theme.breakpointMediaQueries}>
                  {element}
                </BreakpointProvider>
              </ThemeProvider>
            </ScrollContextProvider>
          </CostEstimatesProvider>
        </GlobalContextProvider>
      </AuthStateProvider>
    </QueryClientProvider>
  )
}

Wrapper.propTypes = {
  element: PropTypes.node,
}

exports.wrapPageElement = Wrapper
