import axios from 'axios'
import {
  SpecialtySearchOptions,
  SpecialtySearchResult,
  SpecialtySearchResponse,
} from './ribbon.types'
import { transformSpecialtySearchResponse } from './specialty.transforms'
import { serializeAsQueryString } from 'utils/query.utils'

type ConfigureSearchSpecialtiesServiceType = (
  INSURANCES_ENDPOINT_URL: string,
) => (
    token: string,
    input: SpecialtySearchOptions,
  ) => Promise<SpecialtySearchResult>

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const buildQueryFromOptions = (options: SpecialtySearchOptions) => {
  const serviceOptions: { [key: string]: any } = {}

  if (options.search !== undefined) {
    serviceOptions.search = options.search
  }

  return serviceOptions
}

export const configureSearchSpecialtiesService: ConfigureSearchSpecialtiesServiceType = SPECIALTIES_ENDPOINT_URL => (
  token,
  options,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  // https://api.ribbonhealth.com/v1/custom/specialties?search=Gastroenterology
  const url = `${SPECIALTIES_ENDPOINT_URL}?${serializeAsQueryString(
    buildQueryFromOptions(options),
  )}`
  return axios
    .get<SpecialtySearchResponse>(url, { headers })
    .then(res => transformSpecialtySearchResponse(res.data))
}
