import axios from 'axios'
import { AuthenticateResponse, AuthenticateResult, SsoLoginOptions } from 'services/ribbon/ribbon.types'
import { transformAuthResponse } from 'services/ribbon/authenticate.transforms'

export const configureLoginSso = (SSO_LOGIN_URL: string) =>
  async (options: SsoLoginOptions): Promise<AuthenticateResult> => {
    const { uid, token } = options
    const headers = {
      authorization: `Bearer ${token}`,
    }

    const { data: authenticateResponse } = await axios
      .get<AuthenticateResponse>(`${SSO_LOGIN_URL}/${uid}`, {
        headers,
      })
    return transformAuthResponse(authenticateResponse)
  }
