/* eslint-disable react/prop-types */
import React, {
  createContext,
  useReducer,
  useEffect,
  useRef,
  useContext,
} from 'react'
import { services } from 'services'
import {
  loadFromLocalStorage,
  LOCAL_STORAGE_KEYS,
  saveToLocalStorage,
} from 'utils/localstorage'

const defaultState = {
  token: undefined,
  name: undefined,
  permissionLevel: undefined,
  isFetching: true,
  error: undefined,
  modules: undefined,
  filters: undefined,
  customFields: undefined,
}

export const AUTH_ACTION_TYPES = {
  LOGGED_OUT: 'LOGGED_OUT',
  LOGGED_IN: 'LOGGED_IN',
  FETCHING_AUTH: 'FETCHING_AUTH',
  FETCHING_AUTH_SUCCESS: 'FETCHING_AUTH_SUCCESS',
}

const reducer = (state, action) => {
  console.log(action)
  const { type, payload = {} } = action

  switch (type) {
    case AUTH_ACTION_TYPES.LOGGED_IN: {
      saveToLocalStorage(LOCAL_STORAGE_KEYS.auth, payload)
      return {
        ...state,
        isFetching: false,
        ...payload,
      }
    }
    case AUTH_ACTION_TYPES.LOGGED_OUT: {
      saveToLocalStorage(LOCAL_STORAGE_KEYS.auth, {})
      return {
        ...defaultState,
        isFetching: false,
        ...payload,
      }
    }
    case AUTH_ACTION_TYPES.FETCHING_AUTH:
      return {
        ...state,
        isFetching: true,
      }
    case AUTH_ACTION_TYPES.FETCHING_AUTH_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }

    default:
      return state
  }
}

export const AuthStateContext = createContext(defaultState)

export const AuthStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    ...loadFromLocalStorage(LOCAL_STORAGE_KEYS.auth),
  })

  const { token, modules, filters, isFetching } = state
  const authChecked = useRef(false)
  // console.log('AuthState', state)

  useEffect(() => {
    // All users should have modules / filters / token to continue on to fca
    if ((!token || !modules || !filters) && isFetching) {
      dispatch({
        type: AUTH_ACTION_TYPES.LOGGED_OUT,
      })
      authChecked.current = false
    }

    if (token) {
      if (!authChecked.current) {
        authChecked.current = true
        services.ribbon
          .checkAuth(token)
          .then(() => {
            dispatch({ type: AUTH_ACTION_TYPES.FETCHING_AUTH_SUCCESS })
          })
          .catch(err => {
            console.log('Check Auth Err:', err)
            dispatch({
              type: AUTH_ACTION_TYPES.LOGGED_OUT,
            })
            authChecked.current = false
          })
      }
    }
  }, [token, isFetching, modules, filters])

  return (
    <AuthStateContext.Provider value={[state, dispatch]}>
      {children}
    </AuthStateContext.Provider>
  )
}

export const useAuthState = () => {
  return useContext(AuthStateContext)
}

export const AuthStateConsumer = AuthStateContext.Consumer
