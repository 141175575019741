import { AuthenticateResponse, AuthenticateResult } from './ribbon.types'

export const transformAuthResponse = (
  res: AuthenticateResponse,
): AuthenticateResult => {
  console.log('AuthenticateResponse:', res)

  const {
    token,
    first_name,
    last_name,
    permission_level,
    modules,
    fca_filters,
    fca_custom_fields,
    algolia_app_id,
    algolia_search_key,
    algolia_index_name,
    ref_typeahead_id,
    ref_typeahead_key,
    ref_typeahead_name,
    user_id,
    fca_user_id,
    is_sso_user,
    has_syncing_typeahead,
  } = res

  return {
    token,
    name: first_name || last_name ? `${first_name} ${last_name}`.trim() : '',
    permissionLevel: permission_level,
    modules: modules,
    filters: fca_filters,
    customFields: fca_custom_fields,
    algoliaAppId: algolia_app_id,
    algoliaSearchKey: algolia_search_key,
    algoliaIndexName: algolia_index_name,
    refTypeaheadId: ref_typeahead_id,
    refTypeaheadKey: ref_typeahead_key,
    refTypeaheadName: ref_typeahead_name,
    userId: user_id,
    fcaUserId: fca_user_id,
    isSsoUser: is_sso_user,
    hasSyncingTypeahead: has_syncing_typeahead,
  }
}
