import axios from 'axios'
import { ResetPasswordOptions, ResetPasswordResult } from './ribbon.types'

type ConfigureResetPasswordServiceType = (
  RESET_PASSWORD_ENDPOINT_URL: string,
) => (options: ResetPasswordOptions) => Promise<ResetPasswordResult>

export const configureResetPasswordService: ConfigureResetPasswordServiceType = RESET_PASSWORD_ENDPOINT_URL => options => {
  return axios
    .post<ResetPasswordResult>(RESET_PASSWORD_ENDPOINT_URL, options)
    .then(res => res.data)
}
