import axios from 'axios'
import { LocationSearchResponse, ProviderSearchResponse } from './ribbon.types'

type ConfigureDevelopersSearchDataServiceType = (
  ENDPOINT_URL: string,
) => (
  token: string,
  type: 'providers' | 'locations',
  searchQuery: string,
) => Promise<ProviderSearchResponse | LocationSearchResponse>

export const configureDevelopersSearchDataService: ConfigureDevelopersSearchDataServiceType = ENDPOINT_URL => (
  token,
  type,
  searchQuery,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  const url = `${ENDPOINT_URL}/${type}?${searchQuery}`

  return axios
    .get<ProviderSearchResponse | LocationSearchResponse>(url, { headers })
    .then(res => {
      return res.data
    })
}
