import queryString from 'query-string'
import {
  InsuranceSearchOptions,
  InsuranceSearchResult,
  InsuranceSearchResponse,
} from './ribbon.types'
import { transformInsuranceSearchResponse } from './insurance.transforms'
import { get } from 'services/axios'

export const searchInsurances = (
  options: InsuranceSearchOptions,
): Promise<InsuranceSearchResult> => {
  // https://api.ribbonhealth.com/v1/custom/insurances?search=aetna
  const url = `/custom/insurances?${queryString.stringify(options, {
    arrayFormat: 'comma',
  })}`

  return get<InsuranceSearchResponse>(url).then(res =>
    transformInsuranceSearchResponse(res.data),
  )
}
