import axios from 'axios'
import {
  ClinicalAreasSearchOptions,
  ClinicalAreasSearchResult,
  ClinicalAreasSearchResponse,
  ConditionsSearchResponse,
  TreatmentsSearchResponse,
} from './ribbon.types'
import queryString from 'query-string'
import {
  transformClinicalAreasSearchResponse,
  transformConditionsSearchResponse,
  transformTreatmentsSearchResponse,
} from './clinical-areas.transforms'

type ConfigureSearchClinicalAreasServiceType = (
  ENDPOINT_URL: string,
) => (
  token: string,
  input: ClinicalAreasSearchOptions,
) => Promise<ClinicalAreasSearchResult>

export const configureSearchClinicalAreasService: ConfigureSearchClinicalAreasServiceType = ENDPOINT_URL => (
  token,
  options,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  // https://api.ribbonhealth.com/v1/custom/clinical_areas?search=
  const url = `${ENDPOINT_URL}?${queryString.stringify(options)}`
  return axios
    .get<ClinicalAreasSearchResponse>(url, { headers })
    .then(res => {
      // console.log('res Clinical_Areas :>> ', res.data)
      return transformClinicalAreasSearchResponse(res.data)
    })
}

export const configureSearchConditionsService: ConfigureSearchClinicalAreasServiceType = ENDPOINT_URL => (
  token,
  options,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  // https://api.ribbonhealth.com/v1/custom/conditions?search=
  const url = `${ENDPOINT_URL}?${queryString.stringify(options)}`
  return axios
    .get<ConditionsSearchResponse>(url, { headers })
    .then(res => {
      // console.log('res Conditions :>> ', res.data)
      return transformConditionsSearchResponse(res.data)
    })
}

export const configureSearchTreatmentsService: ConfigureSearchClinicalAreasServiceType = ENDPOINT_URL => (
  token,
  options,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  // https://api.ribbonhealth.com/v1/custom/treatments?search=
  const url = `${ENDPOINT_URL}?${queryString.stringify(options)}`
  return axios
    .get<TreatmentsSearchResponse>(url, { headers })
    .then(res => {
      // console.log('res Treatments :>> ', res.data)
      return transformTreatmentsSearchResponse(res.data)
    })
}
