import { ServicesType } from './services.types'
import { configureRibbonServices } from './ribbon'
import { configureGeocodeService } from './google-maps/geocode.service'

export const services: ServicesType = {
  gmaps: {
    geocode: configureGeocodeService,
  },
  ribbon: configureRibbonServices(),
}
