export const saveToLocalStorage = (key: string, value: unknown): void => {
  //   console.log(`🚀 => saveToLocalStorage => value`, JSON.stringify(value))
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const loadFromLocalStorage = (key: string): any | null => {
  const data =
    (typeof localStorage !== 'undefined' && localStorage.getItem(key)) ||
    undefined
  if (data) {
    try {
      return JSON.parse(data)
    } catch (e) {
      return null
    }
  }
  return null
}

export const LOCAL_STORAGE_KEYS = {
  currentPosition: 'currentPosition',
  auth: 'auth',
}
