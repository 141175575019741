import axios from 'axios'
import { APISuccessResponse } from './ribbon.types'

type ConfigureDeleteUserServiceType = (
  DELETE_USER_ENDPOINT_URL: string,
) => (token: string, userId: number | string) => Promise<APISuccessResponse>

export const configureDeleteUserService: ConfigureDeleteUserServiceType = DELETE_USER_ENDPOINT_URL => (
  token,
  userId,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
  }
  return axios
    .delete<APISuccessResponse>(`${DELETE_USER_ENDPOINT_URL}/${userId}`, {
      headers,
    })
    .then(r => r.data)
}
