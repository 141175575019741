import axios from 'axios'
import { APISuccessResponse, UpdateUserOptions } from './ribbon.types'

type ConfigureUpdateUserServiceType = (
  UPDATE_USER_ENDPOINT_URL: string,
) => (
  token: string,
  userId: string | number,
  options: UpdateUserOptions,
) => Promise<APISuccessResponse>

export const configureUpdateUserService: ConfigureUpdateUserServiceType = UPDATE_USER_ENDPOINT_URL => (
  token,
  userId,
  options,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
  }
  return axios
    .put<APISuccessResponse>(`${UPDATE_USER_ENDPOINT_URL}/${userId}`, options, {
      headers,
    })
    .then(res => res.data)
}
