import { put } from 'services/axios'
import { transformLocation } from './location.transforms'
import {
  UpdateLocationInsurancesOptions,
  UpdateLocationInsurancesResponse,
  UpdateLocationInsurancesResult,
} from './ribbon.types'

export const updateLocationInsurances = async (
  locationId: string,
  options: UpdateLocationInsurancesOptions,
): Promise<UpdateLocationInsurancesResult> => {
  // https://api.ribbonhealth.com/v1/custom/locations/<locationid>/insurances
  const res = await put<UpdateLocationInsurancesResponse>(
    `/custom/locations/${locationId}/insurances`,
    options,
  )
  return transformLocation(res.data)
}
