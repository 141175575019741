/* eslint-disable react/prop-types */
import React, { createContext, useContext, useReducer } from 'react'

export const COST_ESTIMATES_ACTION_TYPES = {
  update: 'UPDATE',
  clear: 'CLEAR',
  clearProcedure: 'CLEAR_PROCEDURE',
}

export const costEstimatesReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case COST_ESTIMATES_ACTION_TYPES.update:
      return {
        ...state,
        ...payload,
      }
    case COST_ESTIMATES_ACTION_TYPES.clear:
      return costEstimatesInitialState
    case COST_ESTIMATES_ACTION_TYPES.clearProcedure:
      return {
        ...state,
        procedureId: undefined,
        procedureDisplay: undefined,
      }

    default:
      break
  }
  return state
}

export const costEstimatesInitialState = {
  procedureId: undefined,
  procedureDisplay: undefined,
  location: undefined,
}

export const CostEstimatesContext = createContext(costEstimatesInitialState)

export const CostEstimatesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    costEstimatesReducer,
    costEstimatesInitialState,
  )

  //   console.log('CostEstimatesProvider:State', state)
  return (
    <CostEstimatesContext.Provider value={[state, dispatch]}>
      {children}
    </CostEstimatesContext.Provider>
  )
}

export const useCostEstimatesContext = () => {
  return useContext(CostEstimatesContext)
}
export const CostEstimatesContextConsumer = CostEstimatesContext.Consumer
