import { put } from 'services/axios'
import {
  UpdateLocationDataOptions,
  UpdateLocationDataResponse,
} from './ribbon.types'

export const updateLocationData = async (
  locationId: string,
  options: UpdateLocationDataOptions,
): Promise<UpdateLocationDataResponse> => {
  // https://api.ribbonhealth.com/v1/custom/locations/<locationid>
  const res = await put<UpdateLocationDataResponse>(
    `/custom/locations/${locationId}`,
    options,
  )
  return res.data
}
