import axios from 'axios'
import {
  CreateCustomFieldResponse,
  CreateCustomFieldOptions,
} from './ribbon.types'

type ConfigureCreateFieldServiceType = (
  ENDPOINT_URL: string,
) => (
  token: string,
  app_uuid: string,
  options: CreateCustomFieldOptions,
) => Promise<CreateCustomFieldResponse>

export const configureCreateCustomFieldService: ConfigureCreateFieldServiceType = ENDPOINT_URL => (
  token,
  app_uuid,
  options: CreateCustomFieldOptions,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
  }
  return axios
    .post<CreateCustomFieldResponse>(
      `${ENDPOINT_URL}?app_uuid_for_request=${app_uuid}`,
      options,
      { headers },
    )
    .then(res => res.data)
}
