import {PartnerData, PartnerDataAPI} from 'services/ribbon/ribbon.types'
import {transformInsuranceAPI} from 'services/ribbon/insurance.transforms'

export const transformPartnerDataAPI: (r: PartnerDataAPI) => PartnerData = r => ({
  partnerId: r.partner_id,
  partnerName: r.partner_name,
  hasTelehealth: r.has_telehealth,
  onlineProfiles: r.online_profiles,
  virtualLocations: (r.virtual_locations ?? []).map(transformPartnerVirtualLocationAPI),
})

export const transformPartnerVirtualLocationAPI: (r: any) => any = r => ({
  state: r.state,
  insurances: (r.insurances ?? []).map(transformInsuranceAPI),
})
