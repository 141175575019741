import { get } from 'services/axios'
import queryString from 'query-string'
import { ProviderSearchOptions, FacetCount } from './ribbon.types'
import { buildQueryFromOptions } from './search-providers.service'

export async function getProviderFacetCounts(facetId: string, searchParams: ProviderSearchOptions): Promise<{ data: FacetCount[] }> {
  const queryParams = queryString.stringify(buildQueryFromOptions(searchParams ?? {}))
  const resp = await get<{ data: FacetCount[] }>(`/custom/providers/filters/${facetId}/counts?${queryParams}`)
  return resp.data
}
