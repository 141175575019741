export const space = ['0', '0.25rem', '0.5rem', '1rem', '2rem', '4rem', '8rem']

export const spacing = {
  zer0: '0',
  one: '0.25rem', // 4px
  two: '0.5rem', // 8px
  three: '1rem', // 16px
  four: '2rem', // 32px
  five: '4rem', // 64px
  six: '8rem', // 128px
}

export const sizes = {
  zer0: '0',
  one: '0.25rem', // 4px
  two: '0.5rem', // 8px
  three: '0.75rem', // 12px
  four: '1rem', // 16px
  five: '1.5rem', // 24px
  six: '2rem', // 32px
  seven: '2.5rem', // 40px
  eight: '3rem', // 48px
  nine: '3.5rem', // 56px
  ten: '4rem', // 64px
  eleven: '4.5rem', // 72px
  twelve: '5rem', // 80px
  thirteen: '6.25rem', // 100px
  fourteen: '8rem', // 128px
}

export const headingSizes = {
  H1: sizes.six,
  H2: sizes.five,
  H3: '1.125rem',
  H4: sizes.four,
}

export const bodySizes = {
  BODY1: sizes.four,
  BODY2: '0.875rem',
  BODY3: '0.65rem',
}
