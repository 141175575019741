import axios from 'axios'
import { transformAuthResponse } from './authenticate.transforms'
import {
  AuthenticateResponse,
  AuthenticateResult,
  CreateAccountOptions,
} from './ribbon.types'

export const configureCreateAccountService = (ENDPOINT_URL: string) => (
  options: CreateAccountOptions,
): Promise<AuthenticateResult> => {
  const headers = {}

  const url = `${ENDPOINT_URL}/${options.uid}/${options.token}`

  return axios
    .post<AuthenticateResponse>(
      url,
      { password: options.password },
      { headers },
    )
    .then(res => {
      return transformAuthResponse(res.data)
    })
}
