import axios from 'axios'
import queryString from 'query-string'
import {
  ListUsersOptions,
  ListUsersResponse,
  ListUsersResult,
} from './ribbon.types'
import { transformListUsersResponse } from './user.transforms'

export type ListUsersPageOptions = {
  page: number
  pageSize: number
}

type ConfigureListUsersServiceType = (
  LIST_USERS_ENDPOINT_URL: string,
) => (token: string, options?: ListUsersOptions) => Promise<ListUsersResult>

export const configureListUsersService: ConfigureListUsersServiceType = LIST_USERS_ENDPOINT_URL => (
  token,
  options,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  const url = `${LIST_USERS_ENDPOINT_URL}?${queryString.stringify(
    options || {},
  )}`

  return axios
    .get<ListUsersResponse>(url, { headers })
    .then(res => transformListUsersResponse(res.data))
}
