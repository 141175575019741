import axios from 'axios'

type ConfigureCheckAuthServiceType = (
  CHECK_AUTH_ENDPOINT_URL: string,
) => (token: string) => Promise<boolean>

export const configureCheckAuthService: ConfigureCheckAuthServiceType = CHECK_AUTH_ENDPOINT_URL => token => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  return axios.get(CHECK_AUTH_ENDPOINT_URL, { headers }).then(() => true)
}
