import React, { useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { searchTypes } from 'utils/constants'
import { AuthStateContext } from './auth-state-context'
import usePrevious from 'hooks/usePrevious'

const INITIAL_FILTERS = {
  providers: {
    minRating: undefined,
    maxDistance: undefined,
    gender: undefined,
    languages: undefined,
    age: undefined,
    conditions: undefined,
    treatments: undefined,
    panelAges: undefined,
    panelSexes: undefined,
    clinicalAreas: undefined,
    procedures: undefined,
    ribbonTrusted: undefined,
    outOfNetwork: undefined,
    min_outcomes_index: undefined,
    min_efficiency_index: undefined,
    showInaccurate: undefined,
    preference: undefined,
  },
  facilities: {
    maxDistance: undefined,
    procedures: undefined,
    outOfNetwork: undefined,
    ribbonTrusted: undefined,
    showInaccurate: undefined,
    clinicalAreas: undefined,
    treatments: undefined,
  },
}

export const GLOBAL_CONTEXT_ACTIONS = {
  DATA_RESET: 'DATA_RESET',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  UPDATE_SEARCH_QUERY: 'UPDATE_SEARCH_QUERY',
  CLEAR_FILTERS: 'CLEAR_FILTERS',
}

const INITIAL_SEARCH_CONTEXT = {
  debugMode: false,
  searchType: undefined, // searchTypes.facilities || providers
  location: undefined,
  prevLocation: undefined,
  providerName: undefined,
  facilityName: undefined,
  insurance: undefined,
  specialties: [],
  facilityType: undefined,
  filters: {
    providers: { ...INITIAL_FILTERS.providers },
    facilities: { ...INITIAL_FILTERS.facilities },
  },
  prevFilters: {
    providers: { ...INITIAL_FILTERS.providers },
    facilities: { ...INITIAL_FILTERS.facilities },
  },
  providersSearchQuery: null,
  facilitiesSearchQuery: null,
  lastUpdatedKey: null,
  providersSearchMode: null,
  facilitiesSearchMode: null,
}

export const GlobalStateContext = React.createContext(undefined)

const searchContextReducer = (state, action) => {
  if (state.debugMode) {
    console.log('Update action:', action)
    console.log('current state:', state)
  }

  const {
    searchType,
    filters,
    providersSearchQuery,
    facilitiesSearchQuery,
  } = state

  if (action.key) {
    const updatedState = {
      ...state,
      [action.key]: action.value,
      lastUpdatedKey: action.key,
    }

    if (action.key === 'insurance' && action.value === undefined) {
      // Out Of Network filter should only be available when insurances are in search
      if (updatedState.filters[searchType].outOfNetwork) {
        updatedState.filters[searchType].outOfNetwork = undefined
      }
    }
    return updatedState
  }

  const { type, payload } = action

  if (type) {
    switch (type) {
      case GLOBAL_CONTEXT_ACTIONS.UPDATE_FILTERS:
        return {
          ...state,
          filters: {
            ...filters,
            [searchType]: {
              ...filters[searchType],
              [payload.key]: payload.value,
            },
          },
          lastUpdatedKey: 'filters',
        }
      case GLOBAL_CONTEXT_ACTIONS.UPDATE_SEARCH_QUERY:
        return {
          ...state,
          ...(searchType === searchTypes.providers && {
            providersSearchQuery: payload,
            lastUpdatedKey: 'providersSearchQuery',
          }),
          ...(searchType === searchTypes.facilities && {
            facilitiesSearchQuery: payload,
            lastUpdatedKey: 'facilitiesSearchQuery',
          }),
        }
      case GLOBAL_CONTEXT_ACTIONS.CLEAR_FILTERS:
        return {
          ...state,
          filters: {
            ...filters,
            [searchType]: {
              ...INITIAL_FILTERS[state.searchType],
              ...((searchType === searchTypes.providers &&
                providersSearchQuery.name) ||
                (searchType === searchTypes.facilities &&
                  facilitiesSearchQuery.name)
                ? { maxDistance: '100' }
                : {}), // providerName and facilityName should always have 10 miles default
            },
          },
          lastUpdatedKey: 'filters',
        }
      case GLOBAL_CONTEXT_ACTIONS.DATA_RESET:
        return INITIAL_SEARCH_CONTEXT

      default:
        return state
    }
  }

  return {
    ...state,
    ...action,
  }
}

export const GlobalContextProvider = ({ children }) => {
  const [authState] = useContext(AuthStateContext)
  const [state, dispatch] = useReducer(
    searchContextReducer,
    INITIAL_SEARCH_CONTEXT,
  )
  const { modules, isFetching: authFetching } = authState
  const { searchType, filters: activeFilters, location } = state
  const prevFilters = usePrevious(activeFilters)
  const prevLocation = usePrevious(location)

  /**
   * Find first enabled searchType - will always have one enabled.
   */
  useEffect(() => {
    if (!searchType && modules && !authFetching) {
      const foundEnabledModuleKey = Object.keys(modules).find(
        key => modules[key].enabled,
      )
      dispatch({ key: 'searchType', value: foundEnabledModuleKey })
      dispatch({ key: `${foundEnabledModuleKey}SearchMode`, value: 'inPerson' })
    }
  }, [modules, authFetching, searchType])

  return (
    <GlobalStateContext.Provider
      value={[{ ...state, prevFilters, prevLocation }, dispatch]}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(GlobalStateContext)
}

GlobalContextProvider.propTypes = {
  children: PropTypes.node,
}
