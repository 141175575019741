import axios from 'axios'
import {
  SearchProceduresCostEstimatesOptions,
  SearchProceduresCostEstimatesResponse,
  SearchProceduresCostEstimatesResult,
} from './ribbon.types'
import queryString from 'query-string'

export const configureSearchProceduresCostEstimatesService = (
  ENDPOINT_URL: string,
) => (
  token: string,
  options: SearchProceduresCostEstimatesOptions,
): Promise<SearchProceduresCostEstimatesResult> => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  // https://api.ribbonhealth.com/v1/procedures_cost_estimates?procedure_ids=&member_zip=
  const url = `${ENDPOINT_URL}?${queryString.stringify(options)}`

  return axios
    .get<SearchProceduresCostEstimatesResponse>(url, { headers })
    .then(res => {
      // console.log('SearchProceduresCostEstimatesResponse:res :>> ', res)
      const { data } = res.data
      return data
    })
}
