export const flexboxgrid = {
  gridSize: 12,
  gutterWidth: 0,
  outerMargin: 0,
  mediaQuery: 'only screen',
  container: {
    xs: 0,
    sm: 48,
    md: 62,
    lg: 90,
    xl: 90,
  },
  breakpoints: {
    xs: 0,
    sm: 50,
    md: 64,
    lg: 90,
    xl: 120,
  },
}

export const breakpointMediaQueries = {
  xs: `
    (min-width: ${flexboxgrid.breakpoints.xs}em)
  `,
  sm: `
    (min-width: ${flexboxgrid.breakpoints.sm}em)
  `,
  md: `
    (min-width: ${flexboxgrid.breakpoints.md}em)
  `,
  lg: `
    (min-width: ${flexboxgrid.breakpoints.lg}em)
  `,
}
