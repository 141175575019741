import axios from 'axios'
import { ManageInstanceOptions, ManageInstanceResponse } from './ribbon.types'

export const configureManageInstanceService = (ENDPOINT_URL: string) => (
  token: string,
  uuid: string,
  options: ManageInstanceOptions,
): Promise<ManageInstanceResponse> => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  const url = `${ENDPOINT_URL}/${uuid}`

  return axios
    .put<ManageInstanceResponse>(url, options, { headers })
    .then(res => {
      console.log('res :>> ', res)
      return res.data
    })
}
