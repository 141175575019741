import { put } from 'services/axios'
import {
  UpdateProviderLocationDataOptions,
  UpdateProviderLocationDataResponse,
} from './ribbon.types'

export const updateProviderLocationData = async (
  providerId: string,
  locationId: string,
  options: UpdateProviderLocationDataOptions,
): Promise<UpdateProviderLocationDataResponse> => {
  // https://api.ribbonhealth.com/v1/custom/providers/<providerId>/locations/<locationid>
  const res = await put<UpdateProviderLocationDataResponse>(
    `/custom/providers/${providerId}/locations/${locationId}`,
    options,
  )
  return res.data
}
