import axios from 'axios'
import { APISuccessResponse, CreateFilterOptions } from './ribbon.types'

type ConfigureCreateFilterServiceType = (
  ENDPOINT_URL: string,
) => (
  token: string,
  app_uuid: string,
  options: CreateFilterOptions,
) => Promise<APISuccessResponse>

export const configureCreateFilterService: ConfigureCreateFilterServiceType = ENDPOINT_URL => (
  token,
  app_uuid,
  options: CreateFilterOptions,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
  }
  return axios
    .post<APISuccessResponse>(
      `${ENDPOINT_URL}?app_uuid_for_request=${app_uuid}`,
      options,
      { headers },
    )
    .then(res => res.data)
}
