export const constructHeroGradient = (angle: number): string =>
  `linear-gradient(${angle}deg, #13B1E5, #A91AE7)`

export const colors = {
  aubergine: '#432D4E',
  aubergine2: '#32213A',
  aubergine3: '#5F3F6E',
  aubergine4: '#8d5fa3',

  aquamarine: '#A7FFD9',
  aquamarine2: '#DBFFF0',
  aquamarine3: '#EBFFF6',
  errorRed: '#BF253B',
  errorRedLight: '#d92942',
  successGreen: '#4BB543',
  coral: '#F25E66',
  coral2: '#F57778',

  gray0: '#231F20',
  gray0Alpha70: 'rgba(35, 31, 32, 0.7)',
  gray0Alpha30: 'rgba(35, 31, 32, 0.3)',
  gray1: '#4F4C4D',
  gray2: '#7B7979',
  gray3: '#A7A5A6',
  gray4: '#D3D2D2',
  gray5: '#EDEDED',
  gray6: '#F5F5F5',
  gray7: '#FAFAFA',
  gray8: '#2F3542',
  gray9: '#767575',

  text: '#231F20',
  textLight: '#4F4C4D',
  textLightest: '#7B7979',
  textDisabled: '#a7a5a6',

  borderInfoWindow: 'rgba(28, 29, 31, 0.08)',
  grayRandom1: '#1C1D1F',

  labelDefault: '#2F3542',
  borderDefault: '#DFE4EA',
  borderDark: '#231F20',
  borderMid: '#7B7979',
  borderLight: '#D3D2D2',

  clear: 'rgba(0, 0, 0, 0)',
  white: '#FFF',
  black: '#333',
  silverLight: '#F7F7F7',
  silverMedium: '#EAEAEA',
  silverDark: '#D8D8D8',
  grayMedium: '#9B9B9B',
  grayDark: '#656565',
  charcoal: '#4A4A4A',
  mint: '#75D6AC',
  mintAlpha10: '#F1FAF6',
  mintAlpha17: '#E8F8F1',
  mintDark: '#3EBC85',
  stripsRemainingGreen: '#7DC69C',
  brandBlue: '#0066CC',
  brandBlueDark: '#003E7D',
  blueMarine: '#5D8FDF',
  blueMarineLight: '#BFD9F2',
  blueMarineDark: '#3D70C4',
  blueMarineAlpha5: '#F6F9FD',
  blueMarineAlpha10: '#EEF3FB',
  blueMarineAlpha20: '#DFE9F9',
  blueMarineAlpha27: '#d3e0f6',
  metalBlue: '#4172A2',
  skyBlue: '#64BAE9',
  skyBlueAlpha13: '#EBF6FC',
  red: '#FF7B7B',
  orange: '#FC7A5D',
  orangeAlpha20: '#FDEDD3',
  trafficRed: '#CF021B',
  trafficGreen: '#7ED321',
  purple: '#8E3BC4',
  trafficGreenAlpha30: '#D8F1BC',
  trafficRedPastel: '#FBEDEF',
  transparentGrayMedium: 'rgba(198, 198, 198, 0.5)',
  heroGradient: constructHeroGradient(93),
  secondaryGradient: 'linear-gradient(-180deg, #37DBE0, #629CE9)',
  redGradient: 'linear-gradient(-180deg, #FF7B7B, #DC4949)',
  orangeGradient: 'linear-gradient(-180deg, #FFAC73, #F39552)',
  greenGradient: 'linear-gradient(-180deg, #75D6AC, #3EBC85)',
  purpleGradient:
    'linear-gradient(-180deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), #8E3BC4',
  lightRedGradient:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), #BF253B',
}
