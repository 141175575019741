import axios from 'axios'
import { GetProcedureResult, GetProcedureOptions } from './ribbon.types'

export const configureGetProcedureService = (ENDPOINT_URL: string) => (
  token: string,
  options: GetProcedureOptions,
): Promise<GetProcedureResult> => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  // https://api.ribbonhealth.com/v1/procedures/uuid
  const url = `${ENDPOINT_URL}/${options.uuid}`

  return axios
    .get<GetProcedureResult>(url, { headers })
    .then(res => {
      console.log('GetProcedureResult: :>> ', res)
      return res.data
    })
}
