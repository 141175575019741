import queryString from 'query-string'
import { get } from 'services/axios'
import { FIELD_TYPES_FLAT } from 'utils/constants'
import { transformLocationSearchResponse } from './location.transforms'
import {
  LocationSearchOptions,
  LocationSearchResult,
  LocationSearchResponse,
} from './ribbon.types'

export const buildQueryFromOptions = (
  options: LocationSearchOptions,
): { [key: string]: any } => {
  const serviceOptions: { [key: string]: any } = {}

  if (!options) {
    return serviceOptions
  }

  if (options.page) {
    serviceOptions.page = options.page
  }

  if (options.name) {
    serviceOptions.name = options.name
  }

  if (options.location) {
    serviceOptions.location = `${options.location.position.latitude},${options.location.position.longitude}`
  }

  if (options.locationType) {
    serviceOptions.location_types = options.locationType.id
  }

  if (options.insurance) {
    serviceOptions.insurance_ids = options.insurance.id
  }

  if (options.minRating) {
    serviceOptions.min_rating = options.minRating * 2
  }

  if (options.maxDistance) {
    serviceOptions.distance = options.maxDistance
  }

  if (options.outOfNetwork) {
    if (serviceOptions.insurance_ids) {
      delete serviceOptions.insurance_ids
    }
  }

  if (options.clinicalAreas) {
    serviceOptions.clinical_area_ids = options.clinicalAreas.map(ca => ca.uuid)
  }

  if (options.treatments) {
    serviceOptions.treatment_ids = options.treatments.map(t => t.uuid)
  }

  if (options.preference) {
    serviceOptions.preference = options.preference.value
  }

  // Every request should have confidence unless specified by showInaccurate filter.
  if (!options.showInaccurate) {
    serviceOptions.min_confidence = 3
  }

  // Handling custom keys separately based on is_custom field
  // passed through when filter is clicked.
  // Booleans are handled seperately to add True
  const customKeys = Object.keys(options).filter(
    key => options[key] && options[key].is_custom,
  )
  if (customKeys.length > 0) {
    customKeys.forEach(key => {
      if (options[key].values) {
        if (
          options[key].field_type &&
          options[key].field_type.toLowerCase() ===
          FIELD_TYPES_FLAT.boolean.toLowerCase()
        ) {
          serviceOptions[key] = true
        } else {
          serviceOptions[key] = options[key].values
        }
      } else {
        serviceOptions[key] = options[key].value
      }
    })
  }
  return serviceOptions
}

export const getLocationsSearchUrl = (options: LocationSearchOptions): string => {
  // https://api.ribbonhealth.com/v1/custom/locations
  return `/custom/locations?${queryString.stringify(
    buildQueryFromOptions(options),
    {
      arrayFormat: 'comma',
    },
  )}`
}

export const searchLocations = async (
  options: LocationSearchOptions,
): Promise<LocationSearchResult> => {
  const url = getLocationsSearchUrl(options)

  return get<LocationSearchResponse>(url).then(res => {
    console.log(`🚀 => searchLocations: res`, res)

    return transformLocationSearchResponse(res.data)
  })
}
