import axios from 'axios'
import { ResendInviteResult } from './ribbon.types'

type ConfigureResendInviteServiceType = (
  ENDPOINT_URL: string,
) => (token: string, userId: string | number) => Promise<ResendInviteResult>

export const configureResendInviteService: ConfigureResendInviteServiceType = ENDPOINT_URL => (
  token,
  userId,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
  }

  return axios
    .post<ResendInviteResult>(`${ENDPOINT_URL}/${userId}`, {
      headers,
    })
    .then(res => res.data)
}
