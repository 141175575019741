import axios from 'axios'
import {
  UpdateProviderSpecialtyOptions,
  UpdateProviderSpecialtyResponse,
} from './ribbon.types'

type ConfigureUpdateProviderSpecialtyServiceType = (
  ENDPOINT_URL: string,
) => (
  token: string,
  providerId: string,
  options: UpdateProviderSpecialtyOptions,
) => Promise<UpdateProviderSpecialtyResponse>

export const configureUpdateProviderSpecialtyService: ConfigureUpdateProviderSpecialtyServiceType = ENDPOINT_URL => (
  token,
  providerId,
  options,
) => {
  const headers = {
    authorization: `Bearer ${token}`,
    'content-type': 'application/json',
  }

  // https://api.ribbonhealth.com/v1/custom/providers/<providerId>/specialties
  const url = `${ENDPOINT_URL}/${providerId}/specialties`
  console.log(url, options)
  return axios
    .put<UpdateProviderSpecialtyResponse>(url, options, { headers })
    .then(res => {
      console.log('UpdateLocationInsurancesResponse', res.data)
      return res.data
    })
}
