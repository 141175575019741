import {
  SearchProceduresResult,
  SearchProceduresResponse,
} from './ribbon.types'

const parsePageFromNextAndPreviousLinks = (
  next: string | null,
  previous: string | null,
) => {
  if (next) {
    return parseInt(next.charAt(next.length - 1), 10)
  }
  if (previous) {
    return parseInt(previous.charAt(previous.length - 1), 10)
  }
  return 1
}

export const transformSearchProceduresResponse = (
  response: SearchProceduresResponse,
): SearchProceduresResult => {
  const { results = [] } = response

  return {
    metadata: {
      totalCount: response.count,
      page: parsePageFromNextAndPreviousLinks(response.next, response.previous),
    },
    data: results,
  }
}
