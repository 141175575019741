import {
  Specialty,
  SpecialtyAPI,
  SpecialtySearchResponse,
  SpecialtySearchResult,
} from './ribbon.types'

const parsePageFromNextAndPreviousLinks = (next: string, previous: string) => {
  if (next) {
    return parseInt(next.charAt(next.length - 1), 10)
  }
  if (previous) {
    return parseInt(previous.charAt(previous.length - 1), 10)
  }
  return 1
}
export const transformSpecialtyAPI: (r: SpecialtyAPI) => Specialty = r => ({
  uuid: r.uuid,
  taxonomyCode: r.taxonomy_code,
  providerName: r.provider_name,
  colloquial: r.colloquial,
  boardSpecialty: r.board_specialty,
  boardSubSpecialty: r.board_sub_specialty,
  nonMdSpecialty: r.non_md_specialty,
  nonMdSubSpecialty: r.non_md_sub_specialty,
  taxonomy1: r.taxonomy_1,
  taxonomy2: r.taxonomy_2,
  taxonomy3: r.taxonomy_3,
  display: r.display,
  providerType: r.provider_type,
  isPrimary: r.is_primary,
})

export const transformSpecialtySearchResponse: (
  rep: SpecialtySearchResponse,
) => SpecialtySearchResult = (response: SpecialtySearchResponse) => ({
  metadata: {
    totalCount: response.count,
    page: parsePageFromNextAndPreviousLinks(response.next, response.previous),
  },
  data: response.results.map(transformSpecialtyAPI),
})
